@import '@/assets/styles/variables';
@import '@/assets/styles/media';
@import '@/assets/styles/hover';

.career {

  &__title {
    font-style: normal;
    font-weight: 700;
    color: $text-primary_1;
    text-transform: uppercase;

    @include h2_title();
  }

  &__content {
    position: relative;
  }

  &__text-bg {
    padding: toVh(12) 0 toVh(60);
    position: relative;
    font-style: normal;
    font-weight: 700;
    color: #232323;
    display: block;
    max-width: 100%;
    position: relative;
    max-width: 100%;

    @include oswald();
    @include text(toVh(64), 1);
  }

  &__marquee {
    width: 100vw;
    position: absolute;
    top: 50%;
    left: calc(-50vw + 50%);
    transform: translateY(-50%);

    &-item {
      font-weight: 500;

      @include text(toVh(48), 1.2);
    }
  }

  &__circle {
    position: relative;
    width: toVh(550);
    height: toVh(550);
    border-radius: 50%;
    overflow: hidden;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    z-index: 1;
    margin: 0 auto;

    @include on-hover {
      .career__vac {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  &__img {
    height: 100%;
    object-fit: cover;
    position: absolute;
  }

  &__vac {
    display: block;
    position: absolute;
    top: calc(100% / 2 - toVh(51));
    opacity: 0;
    visibility: hidden;
    transition: 0.4s all;

    div {
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 2;
      background: rgba(0, 0, 0, 0.5);
      width: toVh(323);
      height: toVh(102);
      border-radius: toVh(51);
      cursor: pointer;
    }

    span {
      font-weight: 500;
      color: $text-primary_1;

      @include oswald();
      @include text(toVh(32), toVh(40));
    }

    img {
      margin: 0 0 0 toVh(30);
    }
  }

  &__btn {
    position: absolute;
    bottom: toVh(111);
    right: calc(50% - toVh(250));
    transform: translateX(50%);
    z-index: 2;

    &--hh {
      position: absolute;
      top: 0px;
      right: 10%;
      z-index: 2;
    }
  }

  @include small-desktop() {
    &__btn {
      &--hh {
        right: 0;
        top: 0;
      }
    }
  }

  @include tablet() {
    &__content {
      padding-top: toVh(24);
    }

    &__text-bg {
      padding: 0;
    }

    &__marquee {
      &-item {
        @include text(toVh(32), 1.2);
      }
    }

    &__circle {
      width: min(toVh(550), 92%);
      height: auto;
      aspect-ratio: 1 / 1;
    }

    &__vac {
      top: 50%;
      transform: translateY(-50%);
      opacity: 1;
      visibility: visible;

      div {
        width: toVh(56);
        height: toVh(56);

        img {
          width: toVh(19);
          height: toVh(19);
          margin: 0;
        }
      }

      span {
        display: none;
      }
    }
  }

  @include mobile() {

    &__btn {
      top: auto;
      right: 0;
      transform: none;
      bottom: toVh(92);

      &--hh {
        right: 0;
        top: 0;

        svg {
          width: toVh(70);
          height: toVh(70);
        }
      }
    }
  }
}