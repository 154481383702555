@import '@/assets/styles/variables';
@import '@/assets/styles/media';
@import '@/assets/styles/hover';

.jobs {
  width: 100%;
  height: calc(100vh - $header-desktop-height);

  &__container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
  }

  &__title {
    color: $text-primary_1;
    margin-bottom: toVh(30);

    @include h2_title();
  }

  &__resume {
    position: absolute;
    right: toVh(350);
    bottom: toVh(-186);
    width: toVh(254);
    height: toVh(254);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    &-btn {
      width: toVh(160);
      height: toVh(160);

      @include text(toVh(24), toVh(28));
    }

    &-animation {
      position: absolute;
      width: toVh(254);
      height: toVh(254);
      animation: rotate 20s linear infinite;
    }
  }

  &__content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__buttons {
    position: relative;
    margin-top: toVh(-36);
  }

  &__anim-block {
    height: 100%;
  }

  &__footer {
    position: relative;

    &-top {
      width: toVh(66) !important;
      height: toVh(66) !important;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translate(160%, -50%);
    }
  }

  &__questions {
  }

  &__hh {
    position: absolute;
    right: 0;
    bottom: toVh(-70);

    img {
      width: toVh(270 * 1.35);
      height: toVh(73 * 1.35);
    }
  }

  &__any {
    font-weight: 500;
    font-size: toVh(20);
    position: absolute;
    color: #e7e7e7;
    transform: rotate(-7.18deg);
    bottom: toVh(12);
    right: toVh(100);

    @include oswald();
  }

  &__list-item {
    flex-basis: 70%;

    @include small-desktop {
      flex-basis: 65%;
    }
  }

  @include small-desktop {
    &__top {
      display: none;
    }
  }

  @include laptop() {
    height: auto;
    padding-bottom: 0;

    &__title {
      margin-bottom: toVh(36);
    }

    &__questions {
      max-height: toVh(120);
    }

    &__buttons {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: toVh(38);
      margin-top: toVh(40);
    }

    &__hh {
      position: relative;
      top: toVh(-16);
      left: toVh(16);
    }

    &__resume {
      position: static;
    }

    &__any {
      display: block;
      position: relative;
      top: toVh(8);
      right: toVh(16);
      font-size: toVh(18);
    }
  }

  @include mobile() {
    &__footer {
      &-top {
        display: none;
      }
    }

    &__resume {
      width: toVh(168);
      height: toVh(168);

      &-animation {
        width: toVh(168);
        height: toVh(168);
      }
    }
  }
}

@keyframes rotate {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
