@import '@/assets/styles/media';
@import '@/assets/styles/variables';

.mySwiper {
  overflow: visible !important;

  :global(.swiper-slide) {
    height: auto;
  }

  @include mobile() {
    gap: toVh(40) !important;

    :global(.swiper-slide) {
      height: 100%;
    }
  }
}

.cases {
  position: relative;

  &__title {
    margin-bottom: toVh(60);
    font-style: normal;
    font-weight: 700;
    color: $text-primary_1;
    text-transform: uppercase;

    @include h2_title();

    @include tablet() {
      margin-bottom: toVh(38);
    }
  }

  /*slide*/
  &-slide {
    position: relative;
    overflow: hidden;
    cursor: pointer;
    padding: toVh(18) toVh(18);
    border: 2px solid $bg-secondary_1;
    border-radius: toVh(28);
    height: toVh(359);
    display: flex;
    justify-content: space-between;
    align-items: end;
    gap: toVh(30);

    &__bgImage {
      position: absolute;
      top: 0;
      left: 0;
      min-width: 100%;
      min-height: 100%;
      height: auto;
      width: auto;
      z-index: -10;
    }

    &__description {
      justify-content: space-between;
      font-size: toVh(18);
      line-height: toVh(28);
      color: $text-primary_1;
      overflow: hidden;

      &_name {
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;

        @include h4_title();
      }
    }

    &__icon {
      max-width: toVh(53);
    }

    &__wrapper {
      position: relative;
    }

    &__tags {
      position: absolute;
      width: 100%;
      color: $text-primary_2;
      text-wrap: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: toVh(18);
      line-height: toVh(28);

      &_tag:hover {
        color: $text-primary_1;

        @include tablet() {
          color: $text-primary_2;
        }
      }
    }
  }
}

.slide {
  color: $text-primary_1;
}

/* swiper */
.slider__container {
  margin: 0 -3.5em;
  padding: 0 3.5em;
  position: relative;
  z-index: 0;
}

.mySwiper {
  position: relative;
}

.slider-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(calc(-50% - toVh(119) / 2));
  width: toVh(80);
  height: toVh(80);
  border-radius: 50%;
  background-color: $bg-primary_2;
  cursor: pointer;
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    user-select: none;
    width: toVh(34);
    height: toVh(41);
  }

  @include tablet() {
    display: none;
  }
}

.arrow-next {
  right: toVh(-40);
}

.arrow-prev {
  left: toVh(-40);

  img {
    transform: rotate(180deg);
  }
}
