@import "@/assets/styles/variables";
@import "@/assets/styles/media";
@import "@/assets/styles/hover";

.services {
  color: $text-primary_1;

  &__title {
    font-style: normal;
    font-weight: 700;
    margin-bottom: 6.9vh;

    @include h2_title();
  }

  @media (min-width: 961px) {
    &__accordion {
      pointer-events: none;
    }
  }

  /* &__accordion {
    &:last-child {
      z-index: 2;

      h3 {
        font-weight: 300;
        color: $text-primary_2;
        letter-spacing: 0.03em;

        @include on-hover {
          color: $text-primary_1;
        }
      }

      &:after {
        height: 0;
        background: none;
        border: toVh(5) dashed $bg-secondary_1;
        top: toVh(-9);
        z-index: 2;
        background: $bg-primary_1
      }

      &::before {
        content: '';
        display: block;
        background: none;
        width: 100%;
        bottom: toVh(-9);
        border: toVh(5) dashed $bg-secondary_1;
        z-index: 1;
      }
    }
  }*/

  @include tablet() {
    &__title {
      margin-bottom: toVh(38);
    }
  }
}
