@import '@/assets/styles/variables';
@import '@/assets/styles/media';
@import '@/assets/styles/hover';

.carousel {
  position: relative;
  width: 100vw;
  height: calc(100vh - $header-desktop-height);
  left: calc(-50vw + 50%);

  &__footer {
    position: absolute;
    width: 100%;
    bottom: toVh(90);
    left: 50%;
    transform: translateX(-50%);

    &-pagination {
      display: flex;
      justify-content: center;
      align-items: center;
      user-select: none;
      gap: toVh(16);

      &-bullet {
        width: toVh(14);
        height: toVh(14);
        border-radius: 50%;
        user-select: none;
        background: $bg-secondary_2;
        cursor: pointer;
      }

      &-bullet-active {
        width: toVh(30);
        height: toVh(30);
        background: $bg-primary_2;
      }
    }

    &-socials {
      position: absolute;
      right: 0;
      top: 0;
      width: toVh(350);
    }
  }

  &__image {
    position: absolute;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 150%;
    user-select: none;
    border-radius: 50%;

    &-prev {
      left: toVh(-32);
      bottom: toVh(-33);
      width: toVh(492);
      height: toVh(492);
    }

    &-next {
      right: toVh(-40);
      top: 0;
      width: toVh(400);
      height: toVh(400);
    }

    &-current {
      left: 50%;
      top: 50%;
      transform: translate(-50%, calc(-50% - $header-desktop-height / 2));
      width: toVh(600);
      height: toVh(600);
      background-size: auto 70vh;
    }

    &-interactive {
      cursor: pointer;
      transition: 0.3s;

      @include on-hover {
        background-size: auto 100vh;
      }
    }
  }

  @include small-desktop() {
    &__image {
      &-next {
        width: toVh(320);
        height: toVh(320);
      }

      &-prev {
        width: toVh(320);
        height: toVh(320);
      }

      &-current {
        width: toVh(520);
        height: toVh(520);
      }
    }
  }

  @include laptop() {
    height: toVh(900);

    &__footer {
      bottom: toVh(-100);
      display: flex;
      flex-direction: column;
      gap: toVh(20);
      justify-content: center;
      align-items: center;

      &-socials {
        margin-left: 0;
        position: relative;
      }
    }

    &__image {

      &-next,
      &-prev {
        width: toVh(300);
        height: toVh(300);
      }

      &-prev {
        left: toVh(-23);
        bottom: toVh(33);
      }

      &-next {
        right: toVh(-33);
        top: toVh(-33);
      }

      &-current {
        width: toVh(480);
        height: toVh(480);
        background-size: cover;
      }

      &-interactive {

        @include on-hover {
          background-size: cover;
        }
      }
    }
  }

  @include tablet() {
    height: toVh(700);

    &__image {
      &-next {
        width: toVh(200);
        height: toVh(200);
      }

      &-prev {
        width: toVh(200);
        height: toVh(200);
      }

      &-current {
        width: toVh(480);
        height: toVh(480);
      }
    }
  }

  @include mobile() {
    height: toVh(510);

    &__image {
      &-next {
        right: toVh(-(160 / 2));
        width: toVh(160);
        height: toVh(160);
      }

      &-prev {
        left: toVh(-(193 / 2));
        width: toVh(193);
        height: toVh(193);
      }

      &-current {
        width: toVh(285);
        height: toVh(285);
      }
    }
  }
}


.carousel-arrow {
  position: absolute;
  width: toVh(80);
  height: toVh(80);
  border-radius: 50%;
  background-color: $bg-primary_2;
  cursor: pointer;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: toVh(36);
    height: toVh(36);
  }

  @include mobile() {
    width: toVh(54);
    height: toVh(54);

    img {
      width: toVh(24);
      height: toVh(24);
    }
  }
}

.arrow-next {
  right: calc((100vw / 2) - toVh(378));
  top: calc(50% - $header-desktop-height / 2);
  transform: translate(50%, -50%);

  @include small-desktop() {
    transform: translateX(0);
    right: toVh(180);
  }

  @include laptop() {
    right: toVh(200);
    top: 36%;
  }

  @include tablet() {
    right: toVh(20);
  }
}

.arrow-prev {
  left: calc((100vw / 2) - toVh(378));
  top: calc(50% - $header-desktop-height / 2);
  transform: translate(-50%, -50%);

  img {
    transform: rotate(180deg);
  }

  @include small-desktop() {
    transform: translateX(0);
    left: toVh(180);
  }

  @include laptop() {
    left: toVh(200);
    top: 36%;
  }

  @include tablet() {
    left: toVh(20);
  }
}