@import '@/assets/styles/variables';
@import '@/assets/styles/media';

.quote-block__container {
  margin-bottom: toVh(73);
  padding: toVh(60);
  display: flex;
  justify-content: center;
  color: $text-primary_1;
  border: 3px solid $bg-secondary_1;
  border-radius: toVh(28);

  &_content {
    text-align: center;

    @include h3_title() {
      font-size: toVh(32);
      line-height: toVh(42);
      font-weight: 400;

      @include tablet() {
        font-size: toVh(24) !important;
        line-height: toVh(34) !important;
      }
    }
  }

  @include tablet() {
    padding: toVh(24) toVh(18);
  }
}
