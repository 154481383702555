@import '@/assets/styles/variables';
@import '@/assets/styles/media';
@import '@/assets/styles/hover';

.header {
  color: $text-primary_1;
  position: relative;
  padding-right: toVh(60);
  transition: 0.3s;

  @include h3_title();

  &__title {
    display: flex;
    align-items: center;
    gap: toVh(16);
    margin-right: toVh(16);
    // & > *:last-child {
    //     margin-left: 12px;
    // }
  }

  svg {
    width: toVh(54);
    height: toVh(54);
  }

  @include on-hover {
    color: $text-primary_2;
    cursor: pointer;
  }

  @include tablet() {
    padding-right: toVh(36);

    &__title {
      flex-direction: column;
      align-items: flex-start;
      gap: toVh(20);
    }

    svg {
      width: toVh(30);
      height: toVh(30);
    }
  }
}

.icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
}