@import '@/assets/styles/variables';
@import '@/assets/styles/media';

.faq {
  color: $text-primary_1;

  &__title {
    font-style: normal;
    font-weight: 700;
    text-transform: uppercase;
    color: $text-primary_1;
    margin-bottom: toVh(50);

    @include h2_title();
  }

  @include tablet() {
    &__title {
      margin-bottom: toVh(38);
    }
  }
}