@import '@/assets/styles/variables';
@import '@/assets/styles/media';

.team {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;

  &__selected {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }


  &__title {
    color: $text-primary_1;
    position: absolute;
    top: toVh(28);

    @include h2_title()
  }

  &__evapps-team {
    position: absolute;
    left: toVh(212);
    top: toVh(102);
    width: toVh(271);
    height: toVh(73);
    user-select: none;
  }

  &__team {
    position: absolute;
    right: toVh(-32);
    bottom: toVh(136);
    user-select: none;
    width: toVh(194);
    height: toVh(194);
    z-index: 1;
  }

  &__dye {
    position: absolute;
    left: toVh(380);
    top: toVh(200);
    user-select: none;
    width: toVh(539);
    height: toVh(345);
  }

  &__arrow {
    position: absolute;
    right: toVh(411);
    top: toVh(200);
    user-select: none;
    width: toVh(280);
    height: toVh(127);
  }

  @include small-desktop() {
    &__team {
      bottom: toVh(160);
    }

    &__dye {
      left: toVh(76);
      top: toVh(310);
    }

    &__arrow {
      right: toVh(134);
      top: toVh(211);
    }
  }

  @include laptop() {
    &__evapps-team {
      left: toVh(50);
      top: toVh(62);
    }

    &__team {
      bottom: toVh(16);
      z-index: 2;
    }
  }

  @include tablet() {

    &__team {
      width: toVh(150);
      height: toVh(150);
      right: toVh(-16);
    }

    &__evapps-team {
      width: toVh(190);
      height: toVh(51);
      left: toVh(18);
      top: toVh(48);
    }

    &__slider {
      gap: toVh(44);
    }
  }

  @include mobile() {
    &__title {
      top: 0;
    }

    &__dye {
      width: toVh(278);
      height: toVh(180);
      left: toVh(-48);
      top: toVh(160);
    }

    &__arrow {
      width: toVh(144);
      height: toVh(56);
      right: 0;
      top: toVh(161);
    }

    &__evapps-team {
      top: toVh(18);
    }

    &__team {
      bottom: toVh(16);
    }
  }
}