@import '@/assets/styles/variables';
@import '@/assets/styles/media';

.block {
  padding-top: $header-desktop-height;
  width: 100%;
  height: 100vh;

  &__swiper {
    overflow: visible !important;
  }

  &__next {
    position: fixed;
    right: 0;
    bottom: 0;
    width: toVh(200);
    height: toVh(200);
    background: $bg-primary_2;
    color: $text-primary_1;
    border-radius: 50%;
    transform: translate(50%, 50%);
    cursor: pointer;
    z-index: 9;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      pointer-events: none;
      transform: translate(-100%, -100%);
      width: toVh(36);
      height: toVh(36);
    }

    &-revert {
      background: $bg-secondary_1;

      svg {
        color: $text-primary_2;
      }
    }
  }
}