@import '@/assets/styles/media';
@import '@/assets/styles/variables';

.form-layout {
  width: 100vw;
  height: calc(100vh - $header-desktop-height);
  position: fixed;
  top: $header-desktop-height;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  background: $bg-primary_1;
  overflow-y: scroll;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;

  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;

  /* Firefox */
  &::-webkit-scrollbar {
    display: none;
    /* Safari and Chrome */
  }

  &__content {
    position: relative;
    margin-top: toVh(24);
    padding-bottom: toVh(80);
  }

  @include mobile() {
    top: $header-mobile-height;
    height: calc(100vh - $header-mobile-height);
  }
}

.content {
  &__title {
    margin-bottom: toVh(50);
    line-height: 1;
  }

  &__header {
    &-close {
      width: toVh(53);
      height: toVh(53);
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: $bg-primary_2;
      cursor: pointer;
      position: absolute;
      right: 0;
      top: 0;

      img {
        width: toVh(24);
        height: toVh(24);
      }
    }
  }

  &__main {}

  @include tablet() {
    &__header {
      &-close {
        width: toVh(47);
        height: toVh(47);

        img {
          width: toVh(22);
          height: toVh(22);
        }
      }
    }
  }

  @include mobile() {

    &__header {
      &-close {
        width: toVh(34);
        height: toVh(34);

        img {
          width: toVh(16);
          height: toVh(16);
        }
      }
    }

    &__content {
      margin-top: toVh(12);
      padding-bottom: toVh(16);
    }
  }
}