@import '@/assets/styles/variables';
@import '@/assets/styles/media';

.it-outstaffing {
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;

  &__image {
    width: 100%;
    height: auto;

    @include desktop() {
      height: calc(100vh - toVh(155));
    }
  }

  &__title {
    font-style: normal;
    font-weight: 700;
    color: $text-primary_1;
    position: absolute;
    left: 0;
    top: toVh(134);
    max-width: toVh(850);
    text-shadow: 0px 4px 41px $bg-primary_1;

    @include h1_title();
  }

  .video {
    position: relative;
    left: -4.5vw;
    width: 102vw;
  }

  /* &__animation {
    position: relative;
    width: 100%;

    &-title {
      position: absolute;
      color: $text-primary_1;
      width: toVh(764);
      text-shadow: 0px 4px 41px $bg-primary_1;

      @include h1_title();
    }

    svg {
      width: 100%;
      height: calc(100vh - $header-desktop-height - toVh(60));
      overflow: visible;
    }
  } */

  @include laptop() {
    /* &__animation {

      &-title {
        width: toVh(500);

      }

      svg {
        height: auto;
      }
    } */
    &__title {
      max-width: toVh(500);
    }
  }

  @include tablet() {
    /* &__animation-title {
      width: toVh(230);
    } */

    &__title {
      top: toVh(60);
      max-width: toVh(270);
    }
  }

  @include mobile() {
    &__title {
      top: toVh(36);
    }
  }
}
