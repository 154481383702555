@import "@/assets/styles/variables";
@import "@/assets/styles/media";

.career-values {
  position: relative;
  width: 100vw;
  height: calc(100vh - $header-desktop-height);
  left: calc(-50vw + 50%);
  display: flex;
  justify-content: center;
  position: relative;

  &__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
  }

  &__title {
    color: $text-primary_1;
    margin-bottom: toVh(50);

    @include h2_title();
  }

  &__content {
    position: relative;
  }

  &__dye-3 {
    position: absolute;
    bottom: 0;
    left: 0;
  }

  &__dye-4 {
    position: absolute;
    bottom: toVh(-24);
    left: toVh(-5);
  }

  &__sign {
    position: absolute;
    bottom: toVh(-39);
    right: 0;
    transform: translateY(100%);
    width: toVh(342);
    height: toVh(64);
  }

  &__value {
    position: absolute;
    z-index: 3;
    width: toVh(425);
    height: toVh(599);
    top: toVh(-154);
    transform: scale(1.2);

    &-1 {
      width: toVh(425);
      height: toVh(599);
      top: toVh(-98);
      right: toVh(165);
    }

    &-3 {
      width: toVh(538);
      height: toVh(517);
      top: toVh(-66);
      right: toVh(145);
    }

    &-2 {
      width: toVh(583);
      height: toVh(644);
      top: toVh(-44);
      right: toVh(125);
    }
  }

  &__accordion {
    padding: toVh(25) 0 toVh(25) !important;

    &-content {
      width: toVh(700);
      color: $text-secondary_3;
      font-weight: 300;

      @include text(toVh(18), toVh(28));

      &-img {
        display: none;
      }
    }
  }

  @include small-desktop() {
    &__value {
      transform: none;

      &-1 {
        right: toVh(80);
      }

      &-3 {
        right: toVh(20);
      }

      &-2 {
        right: toVh(0);
      }
    }

    &__accordion {
      &-content {
        width: toVh(600);
      }
    }
  }

  @include laptop() {
    height: auto;

    &__title {
      margin-bottom: toVh(20);
    }

    &__accordion {
      &-content {
        width: 100%;

        &-img {
          display: block;
          margin-left: auto;
          object-fit: contain;
          position: relative;

          &-1 {
            width: toVh(221);
            height: toVh(314);
          }

          &-3 {
            width: toVh(224);
            height: toVh(247);
          }

          &-2 {
            width: toVh(230);
            height: toVh(221);
          }
        }
      }
    }

    &__dye-3 {
      position: absolute;
      bottom: toVh(-153);
      left: 0;
      transform: translate(-50%);
    }

    &__dye-4 {
      position: absolute;
      bottom: toVh(-173);
      left: toVh(-5);
      transform: translate(-50%);
    }

    &__sign {
      width: toVh(227);
      height: toVh(41);
    }

    &__value {
      display: none;
    }
  }

  @include tablet() {
    &__accordion {
      padding: toVh(12) 0 toVh(12) !important;
    }
  }

  @include mobile() {
    &__dye-3,
    &__dye-4 {
      transform: scale(0.75);
      left: toVh(-184);
    }

    &__dye-3 {
      bottom: toVh(-150);
    }

    &__dye-4 {
      bottom: toVh(-166);
    }
  }
}
