@import '@/assets/styles/variables';
@import '@/assets/styles/media';
@import '@/assets/styles/hover';

header[data-opened='true'] {
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 20;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background: $bg-primary_1;

  &[data-opened='true'] {
    background: $bg-primary_1 !important;
  }

  &__logo {
    width: toVh(133);
    height: toVh(57);
    background: url(../../../../assets/icons/logo1.svg) no-repeat center/contain;
    cursor: pointer;
  }

  &_transparent {
    background: transparent;
  }

  :not(&[data-opened='true']).header_green {
    background: $bg-primary_2;

    .header__logo {
      background-image: url(../../../../assets/icons/logo2.svg) !important;
    }

    ul li {
      color: $text-secondary_1 !important;
    }

    .phone {
      color: $text-secondary_1 !important;

      span {
        color: $text-primary_1;
      }
    }

    .header__btn {
      color: $text-secondary_1 !important;
    }

    .language {
      color: $text-secondary_1 !important;
    }

    .menu__btn {
      background: $bg-secondary_1 !important;
    }
  }

  &__inner {
    width: 100%;
    z-index: 9;
    display: flex;
    justify-content: space-between;
    gap: toVh(50);
    align-items: center;
    height: $header-desktop-height;
    transition: 0.5s;
  }

  &__soc {
    list-style: none;
    display: flex;
    justify-content: flex-end;
    gap: toVh(30);
    align-items: center;
    margin-left: auto;
    color: $text-primary_2;

    li {
      display: inline-block;
      cursor: pointer;

      img {
        width: toVh(36);
        height: toVh(36);
        user-select: none;

        @include on-hover {
          filter: invert(33%) sepia(84%) saturate(470%) hue-rotate(33deg)
            brightness(88%) contrast(105%);
        }
      }
    }

    @include tablet() {
      li {
        img {
          filter: brightness(0) invert(1);
        }
      }
    }
  }

  &__btn {
    outline: none;
    background: none;
    border: none;
    display: inline-block;
    position: relative;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: toVh(20);
    line-height: toVh(23);
    text-align: center;

    color: $text-primary_1;

    @include on-hover {
      &::before {
        width: 120%;
      }
    }

    &:before {
      content: '';
      position: absolute;
      top: calc(50% + 1px);
      left: 50%;
      transform: translate(-50%, -50%);
      display: block;
      width: toVh(46);
      height: toVh(46);
      background: $bg-primary_2;
      border-radius: toVh(26);
      z-index: -1;
      transition: all 0.7s;
    }
  }

  @include small-desktop() {
    &__btn {
      display: none;
    }

    &__soc {
      color: $text-primary_1;
    }
  }

  @include laptop() {
    &__soc-wrap {
      display: none;
    }
  }

  @include mobile() {
    &__logo {
      width: toVh(85);
      height: toVh(37);
    }

    &__inner {
      height: $header-mobile-height;
      gap: toVh(32);
    }
  }
}

.nav {
  margin-right: auto;
  display: flex;
  justify-content: space-between;

  &__list {
    display: flex;
    gap: toVh(30);
  }

  &__item {
    position: relative;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: toVh(20);
    line-height: toVh(23);
    text-align: center;
    color: $text-primary_1;
    cursor: pointer;

    @include on-hover {
      &::before {
        opacity: 0.5;
      }
    }

    &-active {
      &::before {
        opacity: 1 !important;
      }
    }

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: block;
      width: toVh(26);
      height: toVh(26);
      background: $bg-primary_2;
      border-radius: 50%;
      z-index: -1;
      transition: all 0.7s;
      opacity: 0;
    }
  }

  @include laptop() {
    display: none;
  }
}

.phone {
  display: block;
  font-style: normal;
  font-weight: 500;
  color: $text-primary_1;
  width: toVh(165);

  @include oswald();
  @include text(toVh(21), toVh(31));

  & > a > span {
    color: $text-primary_2;
  }

  @include laptop() {
    width: auto;
    margin-left: auto;
  }
}

.language-wrap {
  @include laptop() {
    display: none;
  }
}

.language {
  font-weight: 500;
  color: $text-primary_1;
  transition: color 0.7s;

  @include oswald();
  @include text(toVh(21), toVh(31));

  @include on-hover {
    color: $text-primary_2;
  }
}

.menu {
  display: none;
  outline: none;
  background: none;
  border: none;
  height: toVh(17);
  width: toVh(24);
  cursor: pointer;

  &-mobile-only {
    display: none;
  }

  &__btn {
    flex: 0 0 100%;
    display: block;
    position: relative;
    width: toVh(24);
    height: toVh(3);
    background: $bg-primary_2;
    border-radius: 16px;
    transition: all 0.5s;

    &:before {
      position: absolute;
      content: '';
      top: toVh(8.5);
      right: 0;
      width: toVh(22);
      height: toVh(3);
      border-radius: inherit;
      background: inherit;
      transition: all 0.5s;
    }

    &:after {
      position: absolute;
      content: '';
      top: toVh(17);
      right: 0;
      width: toVh(19);
      height: toVh(3);
      border-radius: inherit;
      background: inherit;
      transition: all 0.5s;
    }

    &_opened {
      transform: rotate(45deg);
      top: toVh(6);

      &::before {
        width: toVh(24);
        transform: rotate(-90deg);
        top: 0;
      }

      &::after {
        display: none;
      }
    }
  }

  @include desktop() {
    @include on-hover {
      .menu__btn {
        &:before,
        &:after {
          width: toVh(24);
        }
      }
    }
  }

  @include laptop() {
    display: flex;
  }
}
