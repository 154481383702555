@import '@/assets/styles/variables';
@import '@/assets/styles/media';

.list-block__container {
  margin-bottom: toVh(73);
  display: grid;
  grid-template-columns: 20% 70%;
  gap: 5%;
  color: $text-primary_1;

  &_title {
    @include h3_title() {
      @include tablet() {
        font-size: toVh(36) !important;
        line-height: toVh(40) !important;
      }
    }
  }

  &_list {
    font-size: toVh(18);
    line-height: toVh(28);

    &_content {
      padding-block: toVh(21);
      display: grid;
      grid-template-columns: 23% 75%;
      gap: 2%;
      border-top: 1px solid $bg-secondary_1;

      &_name {
        @include h3_title() {
          font-size: toVh(32);
        }
      }

      &_description {
        text-align: right;
        justify-self: end;
      }

      &:last-child {
        border-bottom: 1px solid $bg-secondary_1;
      }

      @include tablet() {
        & {
          grid-template-columns: 1fr;
        }

        &_description {
          justify-self: start;
        }
      }
    }

    @include tablet() {
      font-size: toVh(16);
      line-height: toVh(26);
    }
  }

  @include tablet() {
    grid-template-columns: 1fr;
    gap: toVh(35);

    &_title {
      width: 100%;
    }

    &_list {
      width: 100%;
    }
  }
}
