@import '@/assets/styles/variables';
@import '@/assets/styles/media';

.input-attachment-root {
  &__input {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: toVh(36);

    &-input {
      flex-grow: 1;

      input {
        padding-right: toVh(50);
      }
    }

    &-file {
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: toVh(11);

      & input {
        width: 1px;
        height: 1px;
        visibility: hidden;
        opacity: 0;
        position: absolute;
        z-index: -1;
      }

      & img {
        width: toVh(36);
        height: toVh(34);
      }

      & span {
        color: $text-primary_2;

        @include text(toVh(20), toVh(23.5));
      }
    }
  }

  &__selected {
    display: flex;
    align-items: center;
    margin-top: toVh(36);

    span {
      font-weight: 400;
      font-size: toVh(20);
      color: $text-primary_2;
      margin: 0 toVh(12);
    }

    img:first-of-type {
      width: toVh(24);
      height: toVh(32);
    }

    img:last-of-type {
      cursor: pointer;
      width: toVh(20);
      height: toVh(20);
    }
  }

  &__error {
    color: $text-error_1;
    margin-top: toVh(20);

    @include roboto();
    @include text(toVh(16), 1);
  }
}
