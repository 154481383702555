@import '@/assets/styles/variables';
@import '@/assets/styles/media';
@import '@/assets/styles/hover';

.circle-button {
  position: relative;
  background: transparent;
  outline: none;
  border: none;
  width: toVh(214);
  height: toVh(214);
  font-style: normal;
  font-weight: 500;
  text-align: center;
  color: $text-primary_1;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;

  @include oswald();
  @include text(toVh(32), toVh(40));

  &::before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: $bg-primary_2;
    transition: 0.3s;
  }

  & span {
    z-index: 1;
    display: inline-block;
    width: toVh(180);
  }

  @include on-hover() {
    &::before {
      transform: scale(1.25);
    }
  }

  @include tablet() {
    width: toVh(160);
    height: toVh(160);

    @include text(toVh(24), toVh(28));

    & span {
      width: toVh(120);
    }
  }

  @include mobile() {
    width: toVh(109) !important;
    height: toVh(109) !important;

    font-size: toVh(16) !important;
    line-height: toVh(20) !important;

    & span {
      width: toVh(80);
    }
  }
}