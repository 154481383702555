@import '@/assets/styles/variables';
@import '@/assets/styles/media';

.career-top {
  position: relative;
  width: 100vw;
  height: 100vh;
  margin-top: toVh(-96);
  left: calc(-50vw + 50%);
  background: url('../../assets/img/carrer/top.jpg');
  background-size: 100%;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: inset 0px toVh(-16) toVh(40) toVh(7) $bg-primary_1;

  &__container {
    position: relative;
    width: 100%;
    height: 100%;
  }

  &__img {
    max-width: 100%;
    max-height: 100vh;
    object-fit: contain;
    visibility: hidden;
  }

  &__hh {
    position: absolute;
    right: toVh(80);
    top: toVh(154);
  }

  &__send {
    position: absolute;
    bottom: toVh(147);
    right: toVh(306);
  }

  &__dye-1 {
    position: absolute;
    bottom: 0;
    right: 0;
    transform: translateY(50%);
  }

  &__dye-2 {
    position: absolute;
    bottom: toVh(-40);
    right: toVh(90);
    transform: translateY(50%);
  }

  @include laptop() {
    margin-top: 0;

    &__hh {
      display: none;
    }
  }

  @include laptop() {
    height: auto;

    &__send {
      left: 0;
      bottom: 0;
      transform: translateY(50%);
    }

    &__dye-1 {
      right: toVh(-24);
      width: toVh(197);
      height: toVh(73);
    }

    &__dye-2 {
      right: toVh(24);
      bottom: toVh(-22);
      width: toVh(113);
      height: toVh(78);
    }
  }
}