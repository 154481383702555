@import '@/assets/styles/variables';
@import '@/assets/styles/media';

.text-block__container {
  margin-bottom: toVh(73);
  display: grid;
  grid-template-columns: 20% 75%;
  gap: 5%;
  color: $text-primary_1;

  &_title {
    word-wrap: break-word;

    @include h3_title() {
      @include tablet() {
        font-size: toVh(36) !important;
        line-height: toVh(40) !important;
      }
    }
  }

  &_content {
    font-size: toVh(18);
    line-height: toVh(28);
    white-space: pre-wrap;

    @include tablet() {
      font-size: toVh(16);
      line-height: toVh(26);
    }
  }

  @include tablet() {
    grid-template-columns: 1fr;
    gap: toVh(35);

    &_title {
      width: 100%;
    }

    &_content {
      width: 100%;
    }
  }
}
