// convert px to vh in desktop
$base-size: 900;
$base-size-small: 900;
$base-size-extra-small: 500;

:root {
  --size: #{$base-size-extra-small};
}

@media screen and (min-height: 480px) {
  :root {
    --size: #{$base-size-small};
  }
}

@media screen and (min-height: 768px) {
  :root {
    --size: #{$base-size};
  }
}

@function toVh($px) {
  @return calc(#{$px} / var(--size) * 100 * 1vh);
}

$mq-mobile: 480px;
$mq-tablet: 960px;
$mq-laptop: 1025px;
$mq-desktop: 1440px;

$mobile-container: calc(100vw - toVh(36));
$tablet-container: calc(100vw - toVh(72));
$small-desktop-container: 91vw;
$desktop-container: 91vw;


@mixin mobile {
  @media (max-width: #{$mq-mobile}) {
    @content;
  }
}

@mixin tablet {

  @media (max-width: #{$mq-tablet}),
  (min-width: #{$mq-tablet + 1px}) and (orientation: portrait) {
    @content;
  }
}

@mixin laptop {

  @media (max-width: #{$mq-laptop}),
  (min-width: #{$mq-laptop + 1px}) and (orientation: portrait) {
    @content;
  }
}

@mixin small-desktop {

  @media (max-width: #{$mq-desktop - 1px}),
  (min-width: #{$mq-desktop}) and (orientation: portrait) {
    @content;
  }
}

@mixin desktop {
  @media screen and (min-width: #{$mq-desktop}) and (orientation: landscape) {
    @content;
  }
}

:export {
  mobile: $mq-mobile;
  tablet: $mq-tablet;
  laptop: $mq-laptop;
  desktop: $mq-desktop;
}