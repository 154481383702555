@import '@/assets/styles/media';
@import '@/assets/styles/variables';
@import '@/assets/styles/hover';

.animation {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  pointer-events: none;
  width: toVh(427);
  height: toVh(410);

  &__main {
    width: 100%;
    height: auto;
    overflow: visible;
  }

  @include laptop() {
    top: 40%;
  }

  @include tablet() {
    top: 0;
    transform: translate(-50%, 0);
  }
}

.root {
  position: relative;

  .mySwiper.swiper_opened {
    .swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
    :global(
        .swiper-pagination-horizontal.swiper-pagination-bullets
          .swiper-pagination-bullet
      ) {
      background: $bg-primary_3;
      opacity: 1 !important;
    }

    :global(.swiper-pagination-bullet-active) {
      background: $bg-secondary_1 !important;
    }

    :global(.swiper-slide) {
      height: auto !important;
    }
  }

  .slide__before__fake {
    display: none;
  }

  .slide {
    display: flex;
    justify-content: space-between;

    &_last {
      bottom: toVh(-36);
      position: relative;
      justify-content: flex-start;

      .slide__item {
        position: relative;

        &::after {
          content: '';
          position: absolute;
          left: toVh(221);
          top: toVh(221);
          width: toVh(386);
          height: toVh(218);
          background: url('../../../../assets/icons/arrowSlide.svg') no-repeat;
          background-size: 100%;
          user-select: none;
        }
      }

      .slide__num {
        color: $text-primary_1;
      }
    }

    &__dots {
    }

    &__dot {
      background: $bg-secondary_1;
      border-radius: 50%;
      position: absolute;
      transform: translateY(-50%);
      top: 50%;

      &_1 {
        width: toVh(73);
        height: toVh(73);
        right: calc(100% / 2 - toVh(36) - toVh(230));
      }

      &_2 {
        width: toVh(54);
        height: toVh(54);
        right: calc(100% / 2 - toVh(27) - toVh(330));
      }

      &_3 {
        width: toVh(34);
        height: toVh(34);
        right: calc(100% / 2 - toVh(17) - toVh(410));
      }

      &_4 {
        width: toVh(24);
        height: toVh(24);
        right: calc(100% / 2 - toVh(12) - toVh(480));
      }

      &_5 {
        width: toVh(12);
        height: toVh(12);
        right: calc(100% / 2 - toVh(6) - toVh(540));
      }
    }

    &__radius {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: toVh(300);
      height: toVh(300);
      background: $bg-secondary_1;
      border-radius: 50%;
      background-clip: padding-box;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition-property: width, height;
      transition-duration: 0.3s;
      z-index: 1;

      &::before {
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        background: $bg-secondary_1;
        border-radius: 50%;
        border: toVh(80) solid rgba(#fff, 0.2);
        box-shadow: 0px 0px 0px toVh(80) rgba(#fff, 0.1);
        pointer-events: none;
        box-sizing: content-box;
        background-clip: padding-box;
        z-index: 0;
      }

      &-text {
        position: absolute;
        pointer-events: none;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        color: $text-primary_2;
        text-align: center;
        max-width: toVh(200);
        z-index: 1;

        &-mobile {
          display: none;
        }

        @include h3_title();
      }

      @include on-hover {
        width: toVh(360);
        height: toVh(360);
      }
    }

    &__icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      max-height: 100%;
    }

    &__title {
      color: $text-primary_1;

      @include h3_title();
      line-height: toVh(58);
    }

    &__num {
      display: flex;
      align-items: center;
      gap: toVh(4);
      font-style: normal;
      font-weight: 500;
      color: currentColor;

      @include oswald();
      @include text(toVh(115), 1);

      &::before {
        content: '';
        display: block;
        width: toVh(93);
        height: toVh(93);
        border-radius: 50%;
        background: currentColor;
      }
    }

    &__item {
      // min-height: ;
      flex: 0 0 33.33%;
      display: flex;
      flex-direction: column;
      align-items: start;
      gap: toVh(36);

      &1 {
        color: $text-primary_2;
      }

      &2 {
        color: $text-secondary_1;

        & .slide__title {
          color: currentColor;
        }
      }
    }

    &__item-center {
      flex-direction: row;
      justify-content: center;
    }
  }

  @include small-desktop() {
    .slide {
      &_last {
        .slide__item {
          &::after {
            display: none;
          }
        }
      }
    }
  }

  @include tablet() {
    .slide__before__fake {
      display: block;
      width: toVh(427);
      height: toVh(410);
    }
  }

  @include tablet() {
    .slide {
      flex-direction: column-reverse;
      gap: 0;

      &__dots {
        display: none;
      }

      &_last {
        .slide__title {
          flex: 0 1 auto;
        }

        .slide__item {
          transform: translateY(toVh(196));
        }
      }

      &__radius {
        position: relative;
        left: 0;
        top: 0;
        transform: translate(0, toVh(40));
        margin: 0 auto;
        width: toVh(175);
        height: toVh(175);

        &::before {
          border-width: toVh(40);
          border-color: rgba(#fff, 0.2);
          box-shadow: 0px 0px 0px toVh(40) rgba(#fff, 0.1);
        }

        &-text {
          position: static;
          color: $text-primary_2;
          text-align: center;
          max-width: toVh(150);

          @include h3_title();

          &-mobile {
            display: inline-block;
            transform: none;
          }

          &-desktop {
            display: none;
          }
        }

        @include on-hover {
          width: toVh(220);
          height: toVh(220);
        }
      }

      &__title {
        line-height: 1.4;
      }

      &__num {
        @include text(toVh(83), toVh(60));

        &::before {
          width: toVh(60);
          height: toVh(60);
        }
      }

      &__item {
        position: relative;
        flex-basis: auto;
        flex-direction: row;
        gap: toVh(16);

        &2 {
          display: none;
        }
      }
    }
  }

  @include mobile() {
    .mySwiper {
      gap: toVh(70) !important;
    }
  }
}
