@import '@/assets/styles/variables';
@import '@/assets/styles/media';
@import '@/assets/styles/hover';

:not(.nav-drop-opened).nav-drop {
  max-width: calc(100vw - 10%) !important;
}

.nav-drop {
  z-index: 4;
  width: 100%;
  max-height: 0;
  padding: 0;
  overflow-x: hidden;
  overflow-y: hidden;
  transition: 0.3s;
  display: flex;
  justify-content: space-between;

  &-opened {
    padding: toVh(20) 0 toVh(60);
    max-height: toVh(200);
  }

  &__list {
    display: grid;
    gap: toVh(32) toVh(112);
    grid-template-columns: repeat(4, toVh(150));
    grid-template-areas:
      "block1 block4 block7 block10"
      "block2 block5 block8 block11"
      "block3 block6 block9 block12";

  }

  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-mobile {
      display: none;
    }
  }

  &__item {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: toVh(20);
    line-height: 1;
    color: $text-primary_1;
    opacity: 0.6;
    cursor: pointer;
    transition: opacity 0.3s;

    &-laptop {
      display: none;
    }

    @include on-hover {
      opacity: 1
    }

    @for $i from 1 to 12 {
      &.item#{$i} {
        grid-area: block#{$i};
      }
    }

    a {
      display: flex;
      align-items: center;
      line-height: 1;

      img {
        margin-left: toVh(15);
      }
    }
  }

  &__btn {
    position: relative;
    background: transparent;
    border: none;
    outline: none;
    font-style: normal;
    font-weight: 700;
    text-align: center;
    color: $text-primary_1;
    text-transform: uppercase;
    z-index: 1;
    width: toVh(300);

    @include oswald();
    @include text (toVh(30), toVh(30));

    @include on-hover {
      &::before {
        width: 100%;
      }
    }

    &::before {
      position: absolute;
      left: 50%;
      top: calc(50% - 2px);
      transform: translate(-50%, -50%);
      z-index: -1;
      content: '';
      display: block;
      width: toVh(114);
      height: toVh(114);
      border-radius: toVh(60.5);
      background: $bg-primary_2;
      transition: all 0.5s;
    }
  }

  @include small-desktop() {
    &-opened {
      max-height: min(toVh(600), calc(100vh - $header-desktop-height));
    }

    &__list {
      grid-template-columns: repeat(2, toVh(150));
      grid-template-areas:
        "block1 block7"
        "block2 block8"
        "block3 block9"
        "block4 block10"
        "block5 block11"
        "block6 block12";
    }
  }

  @include laptop() {

    flex-direction: column;
    justify-content: flex-start;
    gap: toVh(50);

    &__list {
      display: flex;
      flex-direction: column;
      gap: toVh(18);
    }

    &-opened {
      overflow-y: scroll;
    }

    &__footer {
      align-items: flex-start;

      &-mobile {
        display: flex;
        gap: toVh(27);
        transform: translateY(toVh(-5));
      }
    }

    &__item {
      &:last-child {
        padding-bottom: toVh(20);
      }

      &-laptop {
        display: block;
      }
    }
  }

  @include tablet() {
    &__btn {
      width: auto;
      @include text (toVh(20), 1);
    }
  }

  @include mobile() {
    &-opened {
      max-height: min(toVh(600), calc(100vh - $header-mobile-height));
    }

    &__btn {
      margin-bottom: toVh(32);

      &::before {
        width: toVh(73);
        height: toVh(73);
      }

      @include on-hover {
        &::before {
          width: toVh(73);
          border-radius: 50%;
        }
      }
    }
  }
}