@import '@/assets/styles/variables';
@import '@/assets/styles/media';

.blog-details-top {
  position: relative;
  width: 100vw;
  height: 100vh;
  left: calc(-50vw + 50%);
  padding: toVh(208) toVh(80) toVh(26);
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: bottom;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
  color: $text-primary_1;

  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: toVh(20);

    &_header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &_tags {
        font-size: toVh(18);
        color: $text-primary_2;

        &_tag:hover {
          color: $text-primary_1;
        }
      }

      &_date {
        font-size: toVh(18);
        line-height: toVh(21);

        @include mobile() {
          position: absolute;
          right: toVh(16);
          bottom: toVh(25);
          font-size: toVh(16);
        }
      }
    }

    &_title {
      @include h2_title() {
        font-size: toVh(62);

        @include tablet() {
          font-size: toVh(36) !important;
        }
      }
    }

    &_description {
      font-size: toVh(18);
      line-height: toVh(28);
      width: 50%;

      @include tablet() {
        font-size: toVh(16);
        width: 100%;
      }
    }
  }

  &__share {
    display: flex;
    gap: toVh(36);

    & > a {
      display: flex;
      align-items: center;
    }
  }

  @include small-desktop() {
    background-position: top right 20%;
    background-size: cover;
  }

  @include laptop() {
    height: toVh(660);
    background-position: top right 20%;
    background-size: cover;
    padding: toVh(110) toVh(15) toVh(26);
  }

  @include mobile() {
    padding: toVh(80) toVh(15) toVh(26);
  }
}

.description {
  margin-top: toVh(55);
  color: $text-primary_1;
  font-size: toVh(18);
  line-height: toVh(28);
}
