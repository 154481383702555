@import '@/assets/styles/variables';
@import '@/assets/styles/media';

.image-block__container {
  margin-bottom: toVh(73);
  display: flex;
  gap: toVh(11);
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  color: $text-primary_1;

  &_image {
    max-width: 100%;
    border-radius: toVh(28);
  }

  &_content {
    width: 75%;
    font-size: toVh(16);
    line-height: toVh(28);
    align-self: end;
    font-style: italic;

    @include tablet() {
      font-size: toVh(14);
      line-height: toVh(16);
    }
  }

  @include tablet() {
    &_image {
      // height: toVh(300);
      // width: auto;
      // object-fit: cover;
    }

    &_content {
      width: 100%;
    }
  }
}
