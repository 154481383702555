@import '@/assets/styles/variables';
@import '@/assets/styles/media';

.persons-block__container {
  margin-bottom: toVh(73);
  margin-left: auto;
  width: 75%;
  display: flex;
  flex-wrap: wrap;
  color: $text-primary_1;

  &_card {
    margin-bottom: toVh(30);
    display: flex;
    align-items: center;
    gap: toVh(30);
    width: 50%;

    &_image {
      width: toVh(150);
      border-radius: 50%;
    }

    &_content {
      display: flex;
      flex-direction: column;

      & p {
        font-size: toVh(18);
        line-height: toVh(28);
      }

      & :first-child {
        font-weight: 700;
      }

      @include tablet() {
        & p {
          font-size: toVh(16);
          line-height: toVh(26);
          text-align: center;
        }
      }
    }

    @include small-desktop() {
      gap: toVh(15);
    }

    @include tablet() {
      margin-bottom: 0;
      width: auto;
      flex-direction: column;
    }
  }

  @include tablet() {
    width: 100%;
    justify-content: center;
    gap: toVh(30);
  }

  @include mobile() {
    width: 100%;
    flex-direction: column;
    align-items: center;
    gap: toVh(30);
  }
}
