@import '@/assets/styles/variables';
@import '@/assets/styles/media';

.footer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: toVh(20) 0;

  &__container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

    @include tablet() {
      margin-bottom: toVh(16);
    }
  }

  &__notes {
    width: 40%;
    color: $text-secondary_4;
    font-size: toVh(12);
    line-height: toVh(14);

    @include tablet() {
      width: 100%;
    }
  }

  &__texts {
    display: flex;
    align-items: center;
    gap: toVh(70);
  }

  &__copy {
    font-weight: 400;
    font-size: toVh(14);
    color: $text-secondary_3;

    @include roboto();

    &-arda {
      &-mobile {
        img {
          width: toVh(57);
          height: toVh(19);
        }

        display: none;
      }

      &-desktop {
        img {
          width: toVh(69);
          height: toVh(23);
        }
      }
    }
  }

  &__policy {
    font-weight: 400;
    font-size: toVh(14);
    color: $text-secondary_2;
    text-decoration-line: underline;
    cursor: pointer;

    @include roboto();
  }

  &__list {
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
  }

  @include small-desktop() {
    &__texts {
      gap: toVh(30);
    }
  }

  @include laptop {
    &__container {
      flex-direction: column-reverse;
      gap: toVh(24);
    }

    &__list {
      width: 100%;
      justify-content: center;
    }

    &__texts {
      width: 100%;
      flex-basis: auto;
    }
  }

  @include mobile() {
    padding: toVh(50) 0 toVh(20);

    &__copy {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      line-height: 1;

      &-arda {
        &-mobile {
          display: block;
        }

        &-desktop {
          display: none;
        }
      }
    }

    &__texts {
      width: min(100%, toVh(300));
      flex-direction: column-reverse;
      align-items: center;
      gap: toVh(10);
    }
  }
}
