@import '@/assets/styles/variables';
@import '@/assets/styles/media';
@import '@/assets/styles/hover';

.button {
  position: relative;
  outline: none;
  background: transparent;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: $text-primary_1;
  font-style: normal;
  font-weight: 500;
  text-transform: uppercase;

  @include oswald();

  &-desktop {
    padding: toVh(42) toVh(5);
    @include text(toVh(32), toVh(40));
    width: toVh(300);

    span {
      z-index: 2;
    }

    &::before {
      position: absolute;
      content: '';
      background: $bg-primary_2;
      border-radius: toVh(60.5);
      z-index: 1;
      top: 0;
      width: toVh(121);
      height: toVh(121);
      transition: 0.5s all;
    }

    @include on-hover {
      &::before {
        width: 100%;
      }
    }
  }

  &-mobile {
    background: $bg-primary_2 !important;
    border-radius: toVh(50);
    color: $text-primary_1;
    padding: toVh(26);

    @include h3_title();
  }
}