@import "@/assets/styles/variables";
@import "@/assets/styles/media";
@import "@/assets/styles/hover";

.request-project {
  &__title {
    color: $text-primary_1;
    margin-bottom: toVh(109);

    @include h2_title();
  }

  &__form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      "name company"
      "email tel"
      "block block"
      "checkbox checkbox";
    grid-row-gap: toVh(64);
    grid-column-gap: toVh(64);

    &__el {
      &-tel {
        grid-area: tel;
      }

      &-name {
        grid-area: name;
      }

      &-company {
        grid-area: company;
      }

      &-email {
        grid-area: email;
      }

      &-block {
        grid-area: block;
        display: flex;

        &-file {
          flex-grow: 1;
        }

        &-btn {
          margin-left: toVh(168);
        }
      }

      &-button {
        grid-area: button;
      }

      &-checkbox {
        transform: translateY(tpVh(-64));
        grid-area: checkbox;

        &-link {
          color: $text-primary_2;
          text-decoration: underline;

          &__error {
            color: $text-error_1;
          }

          @include on-hover {
            text-decoration: none;
          }
        }
      }
    }
  }

  @include small-desktop() {
    &__form {
      &__el {
        &-file {
          &-btn {
            margin-left: toVh(96);
          }
        }
      }
    }
  }

  @include laptop() {
    &__form {
      &__el {
        &-file {
          &-btn {
            margin-left: toVh(50);
          }
        }
      }
    }
  }

  @include tablet() {
    &__title {
      margin-bottom: toVh(45);
    }

    &__form {
      grid-template-columns: 1fr;
      grid-template-areas:
        "name"
        "company"
        "email"
        "tel"
        "block"
        "checkbox"
        "submit";
      grid-row-gap: toVh(44);

      &__el {
        &-checkbox {
          //margin-top: 0;
          transform: translateY(toVh(-12));
        }
      }
    }
  }
}
