@import '@/assets/styles/variables';
@import '@/assets/styles/media';

.technology-list-block__container {
  margin-bottom: toVh(73);
  display: grid;
  grid-template-columns: 20% 70%;
  gap: 5%;
  color: $text-primary_1;

  &_title {
    @include h3_title() {
      @include tablet() {
        font-size: toVh(36) !important;
        line-height: toVh(40) !important;
      }
    }
  }

  &_list {
    font-size: toVh(18);
    line-height: toVh(28);

    &_content {
      height: toVh(95);
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-top: 1px solid $bg-secondary_1;

      &_name {
        @include h3_title() {
          font-size: toVh(32);
        }
      }

      &:last-child {
        border-bottom: 1px solid $bg-secondary_1;
      }

      &_image {
        width: toVh(54);

        @include tablet() {
          width: toVh(30);
        }
      }

      @include tablet() {
        height: toVh(60);
      }
    }

    @include tablet() {
      font-size: toVh(16);
      line-height: toVh(26);
    }
  }

  @include tablet() {
    grid-template-columns: 1fr;
    gap: toVh(35);

    &_title {
      width: 100%;
    }

    &_list {
      width: 100%;
    }
  }
}
