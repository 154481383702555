@import '@/assets/styles/variables';
@import '@/assets/styles/media';

.benefits {
  &__title {
    font-style: normal;
    font-weight: 700;
    text-transform: uppercase;
    color: $text-primary_1;
    margin-bottom: toVh(71);

    &_note {
      cursor: pointer;
    }

    @include h2_title();
  }

  &__block {
    display: flex;
    align-items: flex-start;
    gap: toVh(96);

    &-icon {
      max-width: toVh(530);
      flex-basis: toVh(530);
      flex-grow: 0;
      flex-shrink: 0;

      svg {
        width: 100%;
        height: auto;
        overflow: visible;
      }
    }

    &-list {
      width: 100%;
      flex-basis: 100%;
      flex-grow: 1;

      &-accordion {
        padding: toVh(30) 0 toVh(30) !important;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        &-active {
          h3 {
            color: $text-primary_2 !important;
          }
        }
      }

      :global(.swiper-slide) {
        height: auto;
      }
    }
  }

  @include tablet() {
    &__title {
      margin-bottom: toVh(36);
    }

    &__block {
      flex-direction: column;
      align-items: center;
      gap: toVh(40);

      &-icon {
        width: min(toVh(400), 100%);
        flex-basis: auto;
      }
    }
  }

  @include mobile() {
    &__block {
      &-icon,
      &-list {
        flex-basis: 100%;
        flex-grow: 1;
      }

      &-icon {
        width: min(toVh(290), 100%);
      }

      &-list {
        width: 100%;
      }
    }
  }
}
