@import '@/assets/styles/variables';
@import '@/assets/styles/media';

.input-root {
  position: relative;

  &__input {
    background: transparent;
    border: none;
    outline: none;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    color: $text-primary_1;
    border-bottom: 2px solid $bg-secondary_1;
    padding-bottom: toVh(12);
    width: 100%;
    resize: none;
    overflow: hidden;

    @include text(toVh(24), toVh(28));

    &::placeholder {
      color: $text-primary_1;
    }

    &-error {
      border-bottom: 2px solid $text-error_1;
      color: $text-error_1;

      &::placeholder {
        color: $text-error_1;
      }
    }
  }

  &__error {
    position: absolute;
    font-weight: 300;
    font-size: toVh(16);
    color: $text-secondary_2;
    left: 0;
    bottom: toVh(-36);

    @include roboto();
  }

  @include tablet() {
    &__input {
      padding-bottom: toVh(18);

      @include text(toVh(18), toVh(22));
    }

    &__error {
      top: 0;
      left: toVh(128);
    }
  }
}
