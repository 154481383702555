@import '@/assets/styles/media';
@import '@/assets/styles/variables';

.scheme {
  width: 100vw;
  height: calc(100vh - $header-desktop-height + 1px);
  position: relative;
  top: -1px;
  left: calc(-50vw + 50%);
  display: flex;
  justify-content: center;
  transition: 0.5s;

  &::before {
    content: '';
    position: absolute;
    display: block;
    left: 0;
    width: 100%;
  }

  &__container {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    //justify-content: flex-end;
  }

  &__title {
    margin-bottom: toVh(80);
    color: $text-primary_1;
    text-transform: uppercase;

    @include h2_title();
  }

  :global(.swiper-pagination) {
    bottom: toVh(-86) !important;
  }

  @include laptop() {
    height: auto;
    padding-top: toVh(121);
    padding-bottom: toVh(127);
  }

  @include tablet() {
    padding-top: toVh(56);
    padding-bottom: toVh(56);

    :global(.swiper-pagination) {
      bottom: toVh(-20) !important;
    }

    &__title {
      margin-bottom: toVh(38);
    }
  }
}