@import '@/assets/styles/media';
@import '@/assets/styles/variables';

.mySwiper {
  overflow: visible !important;

  :global(.swiper-slide) {
    height: auto;
  }

  @include mobile() {
    gap: toVh(40) !important;

    :global(.swiper-slide) {
      height: 100%;
    }
  }
}

.review {
  position: relative;

  &__title {
    margin-bottom: toVh(60);
    font-style: normal;
    font-weight: 700;
    color: $text-primary_1;
    text-transform: uppercase;

    @include h2_title();
  }

  /*slide*/
  &-slide {
    position: relative;
    cursor: pointer;
    padding: toVh(25) toVh(20);
    border: 2px solid $bg-secondary_1;
    border-radius: toVh(28);
    height: toVh(359);
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &__title {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: toVh(18);
      line-height: toVh(21);
      color: $text-primary_2;
    }

    &__close {
      background: $bg-primary_2;
      border-radius: 50%;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      top: 0;
      left: 50%;
      width: toVh(53);
      height: toVh(53);
      transform: translate(-50%, -50%);

      img {
        width: toVh(24);
        height: toVh(24);
      }
    }

    &__content {
      margin-top: toVh(16);
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: toVh(18);
      line-height: toVh(28);
      color: $text-primary_1;
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: toVh(36);

      &-not-selected {
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
      }
    }

    &__logo {
      img {
        max-width: 100%;
        max-height: toVh(53);
      }

      &--wrap {
        display: flex;
        align-items: center;
        gap: toVh(18);
      }
    }

    &__surname {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: toVh(18);
      line-height: toVh(21);
      color: $text-primary_1;

      & > span {
        display: block;
        font-weight: 300;
        font-size: toVh(16);
        line-height: toVh(20);
        color: $text-secondary_2;
      }
    }
  }

  @include tablet() {
    &__title {
      margin-bottom: toVh(38);
    }

    &-slide {
      width: 100%;

      &-selected {
        height: 100%;
      }

      &__content {
        font-size: toVh(16);
        line-height: toVh(26);
        margin-bottom: toVh(24);
      }

      &__surname {
        font-size: toVh(16);
        line-height: toVh(20);

        & > span {
          display: block;
          font-size: toVh(16);
          line-height: toVh(18);
        }
      }
    }
  }
}

.slide {
  color: $text-primary_1;
}

/* swipre */
.slider__container {
  margin: 0 -3.5em;
  padding: 0 3.5em;
  position: relative;
  /* min-height: 359px; */
  z-index: 0;
}

.mySwiper {
  position: relative;
}

.slider-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(calc(-50% - toVh(119) / 2));
  width: toVh(80);
  height: toVh(80);
  border-radius: 50%;
  background-color: $bg-primary_2;
  cursor: pointer;
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    user-select: none;
    width: toVh(34);
    height: toVh(41);
  }

  @include tablet() {
    display: none;
  }
}

.arrow-next {
  right: toVh(-40);
}

.arrow-prev {
  left: toVh(-40);

  img {
    transform: rotate(180deg);
  }
}

.card {
  padding: toVh(40) toVh(30);
  display: flex;
  justify-content: space-between;
  gap: toVh(18);
  border: 3px solid #ffffff;
  border-radius: toVh(28);
  background: #000;
  position: relative;
  gap: toVh(60);

  &__title {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: toVh(18);
    line-height: toVh(20);
    color: $text-primary_2;
    margin-top: toVh(20);
  }

  &__logo {
    display: flex;
    flex-direction: column;

    &-title {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: toVh(18);
      line-height: toVh(21);
      color: $text-primary_1;
    }

    &-subtitle {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 300;
      font-size: toVh(18);
      line-height: toVh(21);
      color: $text-primary_1;
    }

    &-img {
      max-width: toVh(100);
      margin-bottom: toVh(17);
    }
  }

  &__content {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: toVh(18);
    line-height: 1.56;
    color: $text-primary_1;
    width: toVh(540);
  }

  @include tablet() {
    width: 100%;
    flex-direction: column-reverse;
    gap: toVh(32);

    &__content {
      width: auto;
    }
  }
}
