@import "@/assets/styles/variables";
@import "@/assets/styles/media";

.mySwiper {
	overflow: hidden !important;

	:global(.swiper-slide) {
		height: auto;
	}

	@include mobile() {
		gap: toVh(40) !important;

		:global(.swiper-slide) {
			height: 100%;
		}
	}
}

.slider__container {
	margin: 0 -3.5em;
	padding: 0 toVh(15);
	position: relative;
	z-index: 0;
	width: 100vw;
}

.case-details-random-cases {
	position: relative;

	&__title {
		color: $text-primary_1;
		margin-bottom: toVh(50);

		@include tablet() {
			margin-bottom: toVh(20);
		}

		@include h3_title() {
			@include tablet() {
				font-size: toVh(36) !important;
			}
		}
	}

	&__slides {
		display: flex;
		gap: toVh(30);
		justify-content: center;

		&_slide {
			width: 33%;

			@include tablet() {
				width: auto;
			}
		}
	}

	@include tablet() {
		width: 100vw;
		left: calc(-50vw + 50%);
		padding: 0 toVh(15);
	}
}
