@import '@/assets/styles/media';
@import '@/assets/styles/variables';

.popup {
  &__window {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.66);

    &-wrap {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &-content {}

    &-close {
      width: toVh(53);
      height: toVh(53);
      display: flex;
      align-items: center;
      justify-content: center;
      background: $bg-primary_2;
      border-radius: 50%;
      cursor: pointer;
      position: absolute;
      top: toVh(0);
      right: toVh(0);
      z-index: 100;
      transform: translate(100%, -100%);

      img {
        user-select: none;
        width: toVh(24);
        height: toVh(24);
      }
    }
  }

  @include small-desktop() {
    &__window {
      &-close {
        left: 50%;
        top: 0;
        transform: translate(-50%, -50%);
      }
    }
  }
}