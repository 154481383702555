@import '@/assets/styles/variables';
@import '@/assets/styles/media';

.filter-button {
  &__tag {
    color: $text-primary_2;

    &_active {
      color: $text-primary_1;
    }
  }

  &__button {
    padding: toVh(7.5) toVh(16);
    color: $text-primary_1;
    background: $bg-secondary_2;
    transition: background 0.2s ease-in-out;
    display: flex;
    align-items: center;
    gap: toVh(10);

    &_active {
      background: $bg-primary_2;
    }

    @media (min-width: 1025px) {
      &:hover {
        background: $bg-primary_2;
      }
    }
  }
}
