@import '@/assets/styles/media';
@import '@/assets/styles/variables';


.marquee {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-wrap: nowrap;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  gap: toVh(40);

  &__item {
    overflow: hidden;
  }

  @include tablet() {
    gap: toVh(32);
  }
}