@import '@/assets/styles/variables';
@import '@/assets/styles/media';

.career {
  @include laptop() {
    &__top {
      margin-bottom: toVh(161);
    }

    &__values {
      margin-bottom: toVh(269);
    }

    &__team {
      margin-bottom: toVh(208);
    }
  }
}