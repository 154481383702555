@import "@/assets/styles/variables";
@import "@/assets/styles/media";

.blog-details-content {
	position: relative;

	@include tablet() {
		width: 100vw;
		left: calc(-50vw + 50%);
		padding: 0 toVh(15);
	}
}
