@import '@/assets/styles/variables';
@import '@/assets/styles/media';


.top {
  width: toVh(80);
  height: toVh(80);
  cursor: pointer;
  background: $bg-primary_2;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: toVh(35);
  right: toVh(35);
  z-index: 4;

  img {
    transform: rotate(-90deg);
    width: toVh(34);
    height: toVh(41);
  }

  @include mobile() {
    width: toVh(55);
    height: toVh(55);

    img {
      width: toVh(20);
      height: toVh(20);
    }
  }
}