@import '@/assets/styles/variables';
@import '@/assets/styles/media';
@import '@/assets/styles/hover';

.mySwiper {
  overflow: visible !important;

  @include mobile() {
    gap: toVh(70) !important;
  }
}

.certificates {
  &__title {
    font-style: normal;
    font-weight: 700;
    color: $text-primary_1;
    margin-bottom: toVh(60);

    @include h2_title();
  }

  &__selected {
    max-width: 100%;
    max-height: 80vh;
    object-fit: cover;
  }

  &__slide {
    position: relative;
    aspect-ratio: 1 / 1;
    width: min(toVh(359), 100%);
    border-radius: 28px;
    border: 2px solid $bg-secondary_1;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin: 0 auto;

    & img {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      margin: 0 auto;
      max-width: calc(100% - 60px);
      z-index: 99;
      max-height: toVh(360);
      object-fit: contain;
    }

    @include on-hover {
      border-color: $bg-primary_2;
    }
  }

  @include laptop() {
    &__slide {
      width: min(toVh(400), 100%);
    }

    &__slide img {
      max-height: toVh(400);
    }
  }

  @include tablet() {
    &__title {
      margin-bottom: toVh(40);
    }
  }

  @include mobile() {
    &__slide img {
      max-height: toVh(346);
    }
  }
}

.slide {
  color: $text-primary_1;
}

/* swipre */
.slider__container {
  margin: 0 -3.5em;
  padding: 0 3.5em;
  position: relative;
  min-height: toVh(159);
  z-index: 0;
}

.mySwiper {
  position: relative;
}

.slider-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(calc(-50% - toVh(119) / 2));
  width: toVh(80);
  height: toVh(80);
  border-radius: 50%;
  background-color: $bg-primary_2;
  cursor: pointer;
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: center;

  &__icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  img {
    user-select: none;
    width: toVh(34);
    height: toVh(41);
  }

  @include tablet() {
    display: none;
  }
}

.arrow-next {
  right: toVh(-40);
}

.arrow-prev {
  left: toVh(-40);

  img {
    transform: rotate(180deg);
  }
}
