@import '@/assets/styles/variables';
@import '@/assets/styles/media';

.check {
  position: relative;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: toVh(18);
  line-height: 1.5;
  color: $text-secondary_2;
  user-select: none;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  gap: toVh(24);

  &__input {
    opacity: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    position: absolute;

    &:checked+.check__box {
      background-color: transparent;
      background-image: url("data:image/svg+xml,%3Csvg width='21' height='15' viewBox='0 0 21 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.0012 7.13551L7.04357 13.1779C7.76743 13.9017 8.12936 14.2637 8.56982 14.2438C9.01028 14.2239 9.3381 13.8308 9.99374 13.0446L20.1863 0.823001' stroke='%235A697A' stroke-linecap='round'/%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      background-position: center;
      background-size: toVh(21) toVh(15);
      filter: brightness(0) invert(1);
    }

    /*focus*/
    &:focus+.check__box {}

    &:checked:focus+.check__box {}

    /*disabled*/
    &:disabled+.check__box {
      background-color: $text-secondary_2;
    }

    &:checked:disabled+.check__box {
      background-color: $text-secondary_2;
    }
  }

  &__box {
    display: block;
    width: toVh(36);
    height: toVh(36);
    background-color: transparent;
    border: 2px solid $text-secondary_2;

    &-error {
      border: 2px solid $text-error_1;
    }
  }

  &__content {
    position: relative;
    display: block;
    bottom: toVh(-1);

    &-error {
      color: $text-error_1;
    }

    &__error {
      position: absolute;
      left: 0;
      bottom: toVh(-36);
      font-weight: 300;
      font-size: toVH(16);
      color: $text-secondary_2;
    }
  }

  @include tablet() {
    &__content {
      &__error {
        display: none;
      }
    }
  }
}