@import '@/assets/styles/media';
@import '@/assets/styles/variables';

.blog {
  &-slide {
    position: relative;
    overflow: hidden;
    cursor: pointer;
    padding: toVh(18) toVh(18);
    border: 2px solid $bg-secondary_1;
    border-radius: toVh(28);
    height: toVh(359);
    display: flex;
    justify-content: space-between;
    align-items: end;
    gap: toVh(30);

    &__bgImage {
      position: absolute;
      top: 0;
      left: 0;
      min-width: 100%;
      min-height: 100%;
      height: auto;
      width: auto;
      z-index: -10;
    }

    &__description {
      justify-content: space-between;
      font-size: toVh(18);
      line-height: toVh(28);
      color: $text-primary_1;
      overflow: hidden;

      &_name {
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;

        @include h4_title();
      }
    }

    &__icon {
      max-width: toVh(53);
    }

    &__wrapper {
      position: relative;
    }

    &__tags {
      position: absolute;
      width: 100%;
      color: $text-primary_2;
      text-wrap: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: toVh(18);
      line-height: toVh(28);

      &_tag:hover {
        color: $text-primary_1;

        @include tablet() {
          color: $text-primary_2;
        }
      }
    }
  }
}
