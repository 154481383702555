@import '@/assets/styles/media';

.container {
  max-width: $desktop-container;
  margin: 0 auto;

  @include small-desktop() {
    max-width: $small-desktop-container;
  }

  @include tablet() {
    max-width: $tablet-container;
  }

  @include mobile() {
    max-width: $mobile-container;
  }
}