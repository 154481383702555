@import '@/assets/styles/variables';
@import '@/assets/styles/media';
@import '@/assets/styles/hover';

.case-details-top {
  position: relative;
  width: 100vw;
  height: 100vh;
  left: calc(-50vw + 50%);
  padding: toVh(208) toVh(80) 0;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: bottom;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  color: $text-primary_1;

  &__content {
    display: flex;
    flex-direction: column;
    gap: toVh(20);
    width: min(toVh(600), 80%);

    &_tags {
      font-size: toVh(18);
      color: $text-primary_2;

      &_tag:hover {
        color: $text-primary_1;
      }
    }

    &_title {
      @include h2_title() {
        font-size: toVh(62);

        @include tablet() {
          font-size: toVh(36) !important;
        }
      }
    }

    &_description {
      font-size: toVh(18);
      line-height: toVh(28);

      @include tablet() {
        font-size: toVh(16);
      }
    }
  }

  &__ref {
    position: absolute;
    right: toVh(80);
    top: toVh(154);

    &__button {
      position: relative;
      display: block;
      width: toVh(172);
      height: toVh(172);
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      &_circle {
        width: toVh(136);
        height: toVh(136);
        border: 2px solid $bg-primary_2;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        & > img {
          width: toVh(36);
        }
      }

      &_animation {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        animation: rotate 20s linear infinite;
        pointer-events: none;

        @include on-hover {
          animation-play-state: paused;
        }
      }
    }

    @include mobile() {
      width: toVh(70);
      height: toVh(70);

      &_circle {
        width: toVh(54);
        height: toVh(54);
      }
    }
  }

  @include small-desktop() {
    background-position: top right 20%;
    background-size: cover;
  }

  @include laptop() {
    height: toVh(660);
    background-position: top right 20%;
    background-size: cover;
    padding: toVh(110) toVh(15) 0;

    &__ref {
      display: none;
    }
  }

  @include mobile() {
    padding: toVh(80) toVh(15) 0;
  }
}

@keyframes rotate {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
