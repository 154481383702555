@import '@/assets/styles/hover';
@import '@/assets/styles/variables';

.socials {
  max-width: toVh(350);
  min-width: min(100%, toVh(350));
  display: flex;
  justify-content: space-between;
  list-style: none;

  li {
    display: inline-block;
    cursor: pointer;
    transition: 0.3s;

    img {
      width: toVh(36);
      height: toVh(36);
      transition: 0.3s;
    }

    @include on-hover {
      img {
        filter: invert(33%) sepia(84%) saturate(470%) hue-rotate(33deg) brightness(88%) contrast(105%);
      }
    }
  }
}