@import '@/assets/styles/variables';
@import '@/assets/styles/media';

.contacts__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - $header-desktop-height);

  @include laptop() {
    height: auto;
    display: block;
  }
}

.contacts {
  display: flex;
  align-items: stretch;
  position: relative;
  gap: toVh(50);

  &__title {
    color: $text-primary_1;
    margin-bottom: toVh(32);

    @include h2_title();
  }

  &__address {
    max-width: toVh(382);
  }

  &__item {
    display: flex;
    flex-direction: column;
    justify-content: start;
    position: relative;
    flex: 1 0;

    &-map {
      flex-grow: 0;
      margin-top: toVh(-96);
    }

    &-write {
      position: absolute;
      z-index: 4;
      left: toVh(-44);
      bottom: toVh(61);
    }
  }

  &__description {
    &>* {
      border-bottom: 1px solid $bg-secondary_1;
    }

    &-line {
      display: block;
      padding: toVh(14) 0 toVh(20);
      font-style: normal;
      color: $text-primary_1;
      font-weight: 300;
      opacity: 1;
      background: transparent;

      @include oswald();
      @include text(toVh(36), toVh(53));

      & span {
        color: $text-primary_2
      }
    }
  }

  &__circle-wrap {
    position: relative;
  }

  &__circle {
    width: toVh(590);
    height: toVh(590);
    border-radius: 50%;
    overflow: hidden;
    z-index: 3;
    display: flex;
    justify-content: center;
    align-items: center;

    iframe {
      width: 100%;
      height: 100;
      border-radius: 50%;
    }
  }

  &__top {
    position: absolute;
    bottom: toVh(35) !important;
    right: toVh(35) !important;
    z-index: 4;
  }

  @include small-desktop() {
    justify-content: space-between;

    &__circle {
      width: toVh(400);
      height: toVh(400);
    }

    &__top {
      right: 0 !important;
      bottom: 0 !important;
      transform: translateY(50%);
    }

    &__item {
      &-write {
        left: toVh(-16);
        bottom: 0;
      }
    }
  }

  @include laptop() {
    &__description {
      &-line {
        @include text(toVh(24), toVh(28));
      }
    }

    &__item {
      &-map {
        margin-top: 0;
      }
    }
  }

  @include tablet() {
    &__circle {
      width: toVh(300);
      height: toVh(300);
    }

    &__title {
      margin-bottom: toVh(38);
    }

    &__item {
      width: 100%;

      &-write {
        left: toVh(-8);
        bottom: toVh(31);
      }
    }

    &__description {
      &-line {
        @include text(toVh(21), 1.1);
        padding: toVh(12) 0 toVh(14);
      }
    }
  }

  @include mobile() {
    flex-direction: column-reverse;
    align-items: center;
    gap: toVh(40);

    &__circle {
      width: 100%;
      height: auto;
      aspect-ratio: 1 / 1;
    }

    &__description {
      &-line {
        padding: toVh(20) 0;
        @include text(toVh(21), 1.33);
      }
    }
  }
}