@import '@/assets/styles/variables';
@import '@/assets/styles/media';
@import '@/assets/styles/hover';

.send-resume {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    'name tel'
    'email email'
    'file file'
    'checkbox checkbox';
  grid-row-gap: toVh(64);
  grid-column-gap: toVh(64);

  &__el {
    &-tel {
      grid-area: tel;
    }

    &-email {
      grid-area: email;
    }

    &-file {
      grid-area: file;
      display: flex;
      position: relative;

      &-file {
        flex-grow: 1;
      }

      &-btn {
        position: absolute;
        right: toVh(0);
        bottom: toVh(-70);
      }
    }

    &-button {
      grid-area: button;
    }

    &-checkbox {
      transform: translateY(toVh(-36));
      grid-area: checkbox;

      &-link {
        color: $text-primary_2;
        text-decoration: underline;

        &__error {
          color: $text-error_1;
        }

        @include on-hover {
          text-decoration: none;
        }
      }
    }
  }

  @include small-desktop() {
    &__el {
      &-file {
        &-btn {
          margin-left: toVh(96);
        }
      }
    }
  }

  @include laptop() {
    &__el {
      &-file {
        &-btn {
          margin-left: toVh(50);
        }
      }
    }
  }

  @include tablet() {
    grid-template-columns: 1fr;
    grid-template-areas:
      'name'
      'tel'
      'email'
      'file'
      'checkbox'
      'button';
    grid-row-gap: toVh(44);

    &__el {
      &-checkbox {
        //margin-top: 0;
        transform: translateY(toVh(-12));
      }
    }
  }
}

.container {
  &__title {
    color: $text-primary_1;
    margin-bottom: toVh(109);

    @include h2_title();
  }

  @include tablet() {
    &__title {
      margin-bottom: toVh(45);
    }
  }
}
