@import '@/assets/styles/variables';
@import '@/assets/styles/media';

.popup-mobile {

  &__image {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &__swiper {
    width: 100vw;
    height: auto;
  }
}

.popup {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  background: rgba(0, 0, 0, 0.66);
  justify-content: center;
  align-items: center;
  display: flex;


  &__wrap {
    width: 100vw;
    height: 100vh;
    position: relative;
  }

  &__swiper {
    width: 100vw;
    height: 100vh;
  }

  &__close {
    width: toVh(53);
    height: toVh(53);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $bg-primary_2;
    cursor: pointer;
    position: absolute;
    right: toVh(24);
    top: toVh(24);
    z-index: 100;

    img {
      width: toVh(24);
      height: toVh(24);
    }
  }

  &__image {
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
  }
}


.slider-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: toVh(100);
  height: toVh(100);
  border-radius: 50%;
  background-color: $bg-primary_2;
  cursor: pointer;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;

  &__icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }


  img {
    width: toVh(36);
    height: toVh(36);
  }

  @include mobile() {
    width: toVh(54);
    height: toVh(54);

    img {
      width: toVh(24);
      height: toVh(24);
    }
  }
}

.arrow-next {
  right: toVh(100);

  @include laptop() {
    right: 0;
  }
}

.arrow-prev {
  left: toVh(100);

  @include laptop() {
    left: 0;
  }

  img {
    transform: rotate(180deg);
  }
}