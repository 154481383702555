@import '@/assets/styles/variables';
@import '@/assets/styles/media';

.home {
  @include laptop() {
    &__outstaffing {
      margin-top: toVh(96);
      margin-bottom: toVh(96);
    }

    &__services {
      margin-bottom: toVh(96);
    }

    &__benefits {
      margin-bottom: toVh(40);
    }

    &__schema {
      margin-bottom: toVh(40);
    }

    &__autsors {
      margin-bottom: toVh(96);
    }

    &__cases {
      margin-bottom: toVh(96);
    }

    &__achievements {
      margin-bottom: toVh(96);
    }

    &__certificates {
      margin-bottom: toVh(96);
    }

    &__review {
      margin-bottom: toVh(96);
    }

    &__career {
      margin-bottom: toVh(96);
    }

    &__faq {
      margin-bottom: toVh(96);
    }

    &__request {
      margin-bottom: toVh(96);
    }
  }
}
