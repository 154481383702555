@import "@/assets/styles/variables";
@import "@/assets/styles/media";
@import "@/assets/styles/hover";

.send-letter {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    "name email"
    "block block"
    "checkbox checkbox";
  grid-row-gap: toVh(64);
  grid-column-gap: toVh(64);

  &__el {
    &-name {
      grid-area: name;
    }

    &-email {
      grid-area: email;
    }

    &-block {
      grid-area: block;
      display: flex;

      &-input {
        flex-grow: 1;
      }

      &-btn {
        margin-left: toVh(168);
      }
    }

    &-button {
      grid-area: button;
    }

    &-checkbox {
      transform: translateY(toVh(64));
      grid-area: checkbox;

      &-link {
        color: $text-primary_2;
        text-decoration: underline;

        &__error {
          color: $text-error_1;
        }

        @include on-hover {
          text-decoration: none;
        }
      }
    }
  }

  @include small-desktop() {
    &__el {
      &-block {
        &-btn {
          margin-left: toVh(96);
        }
      }
    }
  }

  @include laptop() {
    &__el {
      &-block {
        &-btn {
          margin-left: toVh(50);
        }
      }
    }
  }

  @include tablet() {
    grid-template-columns: 1fr;
    grid-template-areas:
      "name"
      "email"
      "block"
      "checkbox"
      "button";
    grid-row-gap: toVh(44);

    &__el {
      &-checkbox {
        //margin-top: 0;
        transform: translateY(toVh(-12));
      }
    }
  }
}
