@import '@/assets/styles/variables';
@import '@/assets/styles/media';

.cookies {
  position: fixed;
  bottom: toVh(50);
  right: toVh(50);
  background: $bg-primary_1;
  max-width: toVh(750);
  z-index: 6;
  border: 1px solid $bg-secondary_1;
  border-radius: toVh(15);
  padding: toVh(25);
  display: flex;
  flex-direction: column;
  align-items: center;
  animation: cookies 4s;

  &__text {
    font-weight: 400;
    font-size: toVh(18);
    color: $text-primary_1;
    text-align: center;
  }

  &__buttons {
    display: flex;
    align-items: center;
    margin-top: toVh(20);
    gap: toVh(10);

    button {
      border: none;
      outline: none;
      background: $bg-primary_2;
      color: $text-primary_1;
      padding: toVh(10) toVh(15);
      border-radius: toVh(15);
    }
  }

  @include laptop() {
    left: 50%;
    bottom: toVh(50);
    max-width: 100%;
    width: 80vw;
    transform: translateX(-50%);
  }

  @include mobile() {
    width: 92vw;
  }

  @media (max-width: 360px) {
    &__buttons {
      flex-direction: column;
    }
  }
}

@keyframes cookies {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}