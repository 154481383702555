@import '@/assets/styles/media';
@import '@/assets/styles/variables';


.swiper-custom-root {
  position: relative;

  .swiper-button-disabled {
    // убирать плавно?
    display: none;
  }

  .swiper {
    overflow: unset;
    overflow-y: unset;
    overflow-x: clip;
    display: flex;
    flex-direction: column;
    gap: toVh(96);
  }

  .swiper-wrapper {
    order: 1;
  }

  .swiper-pagination {
    order: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    user-select: none;
  }

  .swiper-pagination-bullet {
    width: toVh(14);
    height: toVh(14);
    background: $bg-primary_2;
    border-radius: 50%;
    user-select: none;
  }

  .swiper-pagination-bullet-active {
    width: toVh(30);
    height: toVh(30);
  }

  .swiper-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet,
  .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 0 toVh(20);

  }

  .swiper-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet-active,
  .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet-active {
    margin: 0 toVh(12);
  }

  .swiper-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet,
  .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 0 toVh(8) !important;
    background: $bg-secondary_2;
    opacity: 1 !important;
  }

  .swiper-pagination-bullet-active {
    background: $bg-primary_2 !important;
  }
}