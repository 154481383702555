@import '@/assets/styles/variables';
@import '@/assets/styles/media';

.thanks {
  width: 100vw;
  height: 100vh;
  z-index: 99;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(64px);

  &__overlay {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
  }

  &__block {
    z-index: 100;
    display: flex;
    flex-direction: column;
    position: relative;
    width: 70vw;

    h2 {
      color: $text-primary_1;
      max-width: toVh(581);
      margin-bottom: toVh(26);

      @include h2_title();
    }

    span {
      display: block;
      font-weight: 300;
      font-size: toVh(18);
      color: $text-primary_1;
      max-width: toVh(460);

      @include roboto();
    }
  }

  &__close {
    width: toVh(217);
    height: toVh(217);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid $bg-primary_2;
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);

    div {
      width: toVh(101);
      height: toVh(101);
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: $bg-primary_2;
    }
  }

  @include laptop() {
    &__block {
      height: 80vh;
    }

    &__close {
      left: 50%;
      bottom: 0;
      transform: translate(-50%, 100%);
    }

  }

  @include tablet() {
    &__block {
      height: 60vh;
    }

    &__close {
      width: toVh(100);
      height: toVh(100);

      div {
        width: toVh(47);
        height: toVh(47);

        img {
          width: toVh(22);
          height: toVh(22);
        }
      }
    }

    &__block {
      width: 92vw;

      h2 {
        max-width: toVh(220);
        margin-bottom: toVh(18);
      }
    }
  }
}