@import '@/assets/styles/variables';
@import '@/assets/styles/media';

.text-with-bg-block__container {
  position: relative;
  width: 100vw;
  height: toVh(612);
  left: calc(-50vw + 50%);
  margin-bottom: toVh(73);
  color: $text-primary_1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  gap: toVh(45);

  &_bgImage {
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -10;

    @include tablet() {
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      height: toVh(459);
      width: auto;
      object-fit: cover;
    }
  }

  &_wrapper {
    max-width: toVh(645);
    margin-left: 50%;
    display: flex;
    flex-direction: column;
    gap: toVh(20);

    @include tablet() {
      width: 100%;
      margin-left: 0;
      padding: 0 toVh(15);
    }
  }

  &_title {
    @include h3_title() {
      font-size: toVh(32);

      @include tablet() {
        font-size: toVh(24) !important;
      }
    }
  }

  &_content {
    font-size: toVh(18);
    line-height: toVh(28);

    @include tablet() {
      font-size: toVh(16);
      line-height: toVh(26);
    }
  }

  @include tablet() {
    height: 100%;
  }
}
