@import 'media';

// background colors
$bg-primary_1: #000;
$bg-primary_2: #80d207;
$bg-primary_3: #b9e971;
$bg-secondary_1: #fff;
$bg-secondary_2: #3a3a3a;
$bg-secondary_3: #b7b7b7;

// text color
$text-primary_1: #fff;
$text-primary_2: #80d207;
$text-secondary_1: #3a3a3a;
$text-secondary_2: #b7b7b7;
$text-secondary_3: #e7e7e7;
$text-secondary_4: #8e8e8e;
$text-error_1: #ff6060;

// header
$header-desktop-height: toVh(96);
$header-mobile-height: 60px;

// text mixins
@mixin roboto() {
  font-family: 'Roboto';
}

@mixin oswald() {
  font-family: 'Oswald';
}

@mixin text($fontSize, $lineHeight) {
  font-size: $fontSize;
  line-height: $lineHeight;
}

@mixin h1_title() {
  @include oswald();

  font-style: normal;
  font-weight: 500;
  font-size: toVh(96);
  line-height: 1.3;
  text-transform: uppercase;

  @include laptop() {
    font-size: toVh(60) !important;
  }

  @include tablet() {
    font-size: toVh(30) !important;
  }
}

@mixin h2_title() {
  @include oswald();

  font-style: normal;
  font-weight: 500;
  font-size: toVh(96);
  line-height: 1.3;
  text-transform: uppercase;
  @content;

  @include laptop() {
    font-size: toVh(54) !important;
    @content;
  }

  @include tablet() {
    font-size: toVh(30) !important;
    @content;
  }
}

@mixin h3_title() {
  @include oswald();

  font-style: normal;
  font-weight: 500;
  font-size: toVh(40);
  line-height: toVh(58);
  text-transform: uppercase;
  @content;

  @include laptop() {
    font-size: toVh(28) !important;
    line-height: toVh(42);
    @content;
  }

  @include tablet() {
    font-size: toVh(21) !important;
    line-height: toVh(31);
    @content;
  }
}

@mixin h4_title() {
  @include oswald();

  font-style: normal;
  font-weight: 500;
  font-size: toVh(32);
  line-height: toVh(47);
  text-transform: uppercase;
  @content;

  @include laptop() {
    font-size: toVh(28) !important;
    line-height: toVh(42);
    @content;
  }

  @include tablet() {
    font-size: toVh(21) !important;
    line-height: toVh(31);
    @content;
  }
}
