@import "@/assets/styles/variables";
@import "@/assets/styles/media";
@import "@/assets/styles/hover";

.slider__container {
	margin: 0 -3.5em;
	padding: 0 3.5em;
	position: relative;
	z-index: 0;

	@include laptop() {
		width: 100vw;
		padding: 0 15px;
	}
}

.mySwiper {
	max-width: toVh(750);
	gap: toVh(25) !important;

	:global(.swiper-wrapper) {
		display: flex;
		align-items: center;
	}
}

.certificates {
	&__slide {
		position: relative;
		width: fit-content;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
		margin: 0 auto;

		&__image {
			max-width: toVh(650);
			max-height: toVh(629);
			z-index: 99;
			border-radius: 28px;
			border: 2px solid $bg-secondary_1;
			object-fit: cover;
		}

		@include tablet() {
			&__image {
				width: 100%;
				height: auto;
			}
		}

		&-close {
			width: toVh(53);
			height: toVh(53);
			display: flex;
			align-items: center;
			justify-content: center;
			background: $bg-primary_2;
			border-radius: 50%;
			cursor: pointer;
			position: absolute;
			top: toVh(-50);
			right: toVh(-50);
			z-index: 100;

			&__icon {
				user-select: none;
				width: toVh(24);
				height: toVh(24);
			}
		}

		@include tablet() {
			&-close {
				display: none;
			}
		}
	}
}

.slider-arrow {
	position: absolute;
	top: 50%;
	transform: translateY(calc(-50% - toVh(50) / 2));
	width: toVh(80);
	height: toVh(80);
	border-radius: 50%;
	background-color: $bg-primary_2;
	cursor: pointer;
	z-index: 3;
	display: flex;
	justify-content: center;
	align-items: center;

	&__icon {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	img {
		user-select: none;
		width: toVh(34);
		height: toVh(41);
	}

	@include laptop() {
		display: none;
	}
}

.arrow-next {
	right: toVh(-120);
}

.arrow-prev {
	left: toVh(-120);

	img {
		transform: rotate(180deg);
	}
}
