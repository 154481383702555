@import '@/assets/styles/variables';
@import '@/assets/styles/media';
@import '@/assets/styles/hover';

.hh {
  position: relative;
  display: block;
  width: toVh(172);
  height: toVh(172);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    .hh__circle {
      text-decoration: none;
    }
  }

  &__circle {
    width: toVh(136);
    height: toVh(136);
    border: 2px solid $bg-primary_2;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    text-decoration: underline;
    color: $text-primary_2;

    @include roboto();
    @include text(toVh(29), 1);

    &:hover {
      text-decoration: none;
    }
  }

  &__animation {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    animation: rotate 20s linear infinite;
    pointer-events: none;

    @include on-hover {
      animation-play-state: paused;
    }
  }

  @include mobile() {
    width: toVh(70);
    height: toVh(70);

    &__circle {
      width: toVh(54);
      height: toVh(54);

      @include text(toVh(12), 1);
    }
  }
}

@keyframes rotate {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
