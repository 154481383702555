@import 'variables';
@import 'media';
@import 'hover';

*,
*::after,
*::before {
  box-sizing: border-box;
}

body,
html {
  font-family: Roboto, Helvetica, sans-serif;
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  background: $bg-primary_1;
  -webkit-overflow-scrolling: touch;

  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;

  /* Firefox */
  &::-webkit-scrollbar {
    display: none;
    /* Safari and Chrome */
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

input {
  outline: none;

  &:focus {
    outline: none
  }
}

a {
  text-decoration: none;
  color: inherit;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

li {
  margin: 0;
  padding: 0;
}

p {
  margin: 0;
  padding: 0;
}

button,
input[type="submit"],
input[type="reset"] {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0;
  margin-block-start: 0;
  margin-block-end: 0;
  font-weight: 500;
}